<template>
  <div class="pad-home">
    <HeaderBar title="服务协议"
               :showLeft="true"></HeaderBar>
    <div class="protocol-title">{{protocolContent.Title}}</div>
    <!-- <div class="protocol-bytitle">{{protocolContent.ByTitle}}</div> -->
    <div class="protocol-content"
         v-html="protocolContent.Contents"></div>
  </div>
</template>

<script>
import HeaderBar from '@/components/headerbar/headerbar'
import { getProtocol } from '@/service/'

export default {
  name: 'protocol',
  components:{HeaderBar},
  data () {
    return {
      protocolContent: ''
    }
  },
  computed: {
    protocolId () {
      return this.$route.params.id
    }
  },
  methods: {
    async getProtocolContent (id) {
      let res = await getProtocol(id)
      if (res.code == 1) {
        console.log(res)
        this.protocolContent = res.data
      }
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.getProtocolContent(this.protocolId)
    })
  }
}
</script>

<style lang="less" scoped>

.pad-home{
  padding-top: 94px;
}
.protocol {
  &-title {
    margin: 50px auto 30px;
    text-align: center;
    font-size: 36px;
  }
  &-bytitle {
    margin: 15px auto;
    text-align: center;
    font-size: 30px;
  }
  &-content {
    padding: 0 30px;
    line-height: 48px;
    font-size: 26px;
  }
}
</style>
